<template>
  <div class="GoodsOutDoorForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      @update="update"
    >
      <col2-detail>
        <col2-block title="员工基本信息">
          <col2-item label="公司名称" :text="form.companyName" :span="8"></col2-item>
          <col2-item label="员工姓名" :text="form.userName" :span="8"></col2-item>
          <col2-item label="性别" :text="form.gender" :span="8"></col2-item>
          <col2-item label="证件类型" :text="form.cardType" :span="8"></col2-item>
          <col2-item label="证件号码" :text="form.cardNum" :span="8"></col2-item>
          <col2-item label="出生日期" :text="form.birthday" :span="8"></col2-item>
          <col2-item label="民族" :text="form.nation" :span="8"></col2-item>
          <col2-item label="籍贯" :text="form.nativePlace" :span="8"></col2-item>
          <col2-item label="户籍" :text="form.residenceAddress" :span="8"></col2-item>
          <col2-item label="婚姻状况" :text="form.maritalStatus" :span="8"></col2-item>
          <col2-item label="生育状况" :text="form.fertilityStatus" :span="8"></col2-item>
          <col2-item label="政治面貌" :text="form.politicalStatus" :span="8"></col2-item>
          <col2-item label="最高学历" :text="form.educationDegree" :span="8"></col2-item>
          <col2-item label="所学专业" :text="form.major" :span="8"></col2-item>
          <col2-item label="毕业院校" :text="form.graduateSchool" :span="8"></col2-item>
          <col2-item label="第一学历" :text="form.firstEducationDegree" :span="8"></col2-item>
          <col2-item label="所学专业" :text="form.firstMajor" :span="8"></col2-item>
          <col2-item label="毕业院校" :text="form.firstGraduateSchool" :span="8"></col2-item>
          <col2-item label="电子邮箱" :text="form.personalEmailAddress" :span="8"></col2-item>
          <col2-item label="联系电话" :text="form.phoneNum" :span="8"></col2-item>
          <col2-item label="面试时间" :text="form.interviewDate" :span="8"></col2-item>
          <col2-item label="证件信息">
            <!-- <v-preview :imgs="form.annexList"></v-preview> -->
          </col2-item>
        </col2-block>
        <col2-block title="员工录用信息">
          <col2-item label="员工编号" :text="form.jobNum" :span="8"></col2-item>
          <col2-item label="部门" :text="form.department" :span="8"></col2-item>
          <col2-item label="岗位" :text="form.post" :span="8"></col2-item>
          <col2-item label="职级" :text="form.jobRank" :span="8"></col2-item>
          <col2-item label="入职时间" :text="form.entryTime" :span="8"></col2-item>
          <col2-item label="试用期期限" :text="form.probationPeriod" :span="8"></col2-item>
          <col2-item label="试用期薪资" :text="form.probationSalary" :span="8"></col2-item>
          <col2-item label="是否缴纳公积金" :text="form.providentFundFlag" :span="8"></col2-item>
          <col2-item label="是否缴纳社保" :text="form.socialSecurityFlag" :span="8"></col2-item>
        </col2-block>
        <col2-block title="薪资信息">         
            <col2-item
              label="薪资档级"
              :text="form.salaryGrade || '--'"
              :span="8"
            />
            <col2-item
              label="基本工资"
              :text="basicInfo.baseSalary || '--'"
              :span="8"
            />
            <col2-item
              label="绩效工资"
              :text="basicInfo.performanceSalary || '--'"
              :span="8"
            />                 
            <col2-item
              label="社会保险"
              :text="basicInfo.socialInsurance || '--'"
              :span="8"
            />
            <col2-item
              label="社险补贴"
              :text="basicInfo.socialInsuranceSubsidy || '--'"
              :span="8"
            />
            <col2-item
              label="公积金"
              :text="basicInfo.providentFund || '--'"
              :span="8"
            />        
            <col2-item
              label="公积金补贴"
              :text="basicInfo.providentFundSubsidy || '--'"
              :span="8"
            />
            <col2-item
              label="通讯补贴"
              :text="basicInfo.communicationSubsidy || '--'"
              :span="8"
            />
            <col2-item
              label="交通补贴"
              :text="basicInfo.transportationSubsidy || '--'"
              :span="8"
            />                 
            <col2-item
              label="餐贴"
              :text="basicInfo.mealSubsidy || '--'"
              :span="8"
            />
            <col2-item
              label="汽油费补贴"
              :text="basicInfo.petrolSubsidy || '--'"
              :span="8"
            />
            <col2-item
              label="岗位补贴"
              :text="basicInfo.jobRankSubsidy || '--'"
              :span="8"
            />                
            <col2-item
              label="其他补贴"
              :text="basicInfo.otherSubsidy || '--'"
              :span="8"
            />
            <col2-item
              label="考核奖"
              :text="basicInfo.assessmentAward || '--'"
              :span="8"
            />
            <col2-item
              label="高温费"
              :text="basicInfo.highTemperatureFee || '--'"
              :span="8"
            />                 
            <col2-item
              label="社保基数"
              :text="basicInfo.socialInsuranceBase || '--'"
              :span="8"
            />
            <col2-item
              label="公积金基数"
              :text="basicInfo.providentFundBase || '--'"
              :span="8"
            />
            <col2-item
              label="养老基数"
              :text="basicInfo.pensionBase || '--'"
              :span="8"
            />                  
            <col2-item
              label="生育基数"
              :text="basicInfo.fertilityBase || '--'"
              :span="8"
            />
            <col2-item
              label="失业基数"
              :text="basicInfo.unemploymentBase || '--'"
              :span="8"
            />
            <col2-item
              label="工伤基数"
              :text="basicInfo.workInjuryBase || '--'"
              :span="8"
            />                 
            <col2-item
              label="医疗基数"
              :text="basicInfo.medicalBase || '--'"
              :span="8"
            />        
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getEnterpriseUserInfoDetailURL,getSalaryStandardByTenantAndGradeURL } from "./api";
import { Col2Detail, Col2Block, Col2Item } from "components/bussiness";
import { vPreview } from "@/components/control";

export default {
  name: "GoodsOutDoorForm",
  components: {
    Col2Detail,
    Col2Block,
    Col2Item,
    vPreview,
  },
  data() {
    return {
      width: 182,
      form: {},
      submitConfig: {
        queryUrl: getEnterpriseUserInfoDetailURL,
        submitUrl: "",
      },
      basicInfo: {},
    };
  },
  mounted() {
    const { id } = this.$route.query;
    this.$setBreadcrumb("详情");
    this.$refs.formPanel.getData({ id });
  },
  methods: {
    update(data) {
      this.form = data;
      // this.form.annexList = data.annexList.map((item)=>{return {src:item}})
      let _this=this
      _this.getbasicInfoList()
    },
    getbasicInfoList() {
      let params = {
        tenantId: this.form.tenantId,
        salaryGrade: this.form.salaryGrade
      };
      this.$axios.get(getSalaryStandardByTenantAndGradeURL, { params: params }).then((res) => {
        if (res.status === 100 && res.data) {
          this.basicInfo = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .col2-item {
  img {
    object-fit: cover;
  }
}
</style>
