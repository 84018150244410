var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "GoodsOutDoorForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "员工基本信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      label: "公司名称",
                      text: _vm.form.companyName,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "员工姓名",
                      text: _vm.form.userName,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "性别", text: _vm.form.gender, span: 8 },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "证件类型",
                      text: _vm.form.cardType,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "证件号码",
                      text: _vm.form.cardNum,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "出生日期",
                      text: _vm.form.birthday,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "民族", text: _vm.form.nation, span: 8 },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "籍贯",
                      text: _vm.form.nativePlace,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "户籍",
                      text: _vm.form.residenceAddress,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "婚姻状况",
                      text: _vm.form.maritalStatus,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "生育状况",
                      text: _vm.form.fertilityStatus,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "政治面貌",
                      text: _vm.form.politicalStatus,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "最高学历",
                      text: _vm.form.educationDegree,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "所学专业", text: _vm.form.major, span: 8 },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "毕业院校",
                      text: _vm.form.graduateSchool,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "第一学历",
                      text: _vm.form.firstEducationDegree,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "所学专业",
                      text: _vm.form.firstMajor,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "毕业院校",
                      text: _vm.form.firstGraduateSchool,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "电子邮箱",
                      text: _vm.form.personalEmailAddress,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "联系电话",
                      text: _vm.form.phoneNum,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "面试时间",
                      text: _vm.form.interviewDate,
                      span: 8,
                    },
                  }),
                  _c("col2-item", { attrs: { label: "证件信息" } }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "员工录用信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      label: "员工编号",
                      text: _vm.form.jobNum,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "部门",
                      text: _vm.form.department,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: { label: "岗位", text: _vm.form.post, span: 8 },
                  }),
                  _c("col2-item", {
                    attrs: { label: "职级", text: _vm.form.jobRank, span: 8 },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "入职时间",
                      text: _vm.form.entryTime,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "试用期期限",
                      text: _vm.form.probationPeriod,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "试用期薪资",
                      text: _vm.form.probationSalary,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "是否缴纳公积金",
                      text: _vm.form.providentFundFlag,
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "是否缴纳社保",
                      text: _vm.form.socialSecurityFlag,
                      span: 8,
                    },
                  }),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "薪资信息" } },
                [
                  _c("col2-item", {
                    attrs: {
                      label: "薪资档级",
                      text: _vm.form.salaryGrade || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "基本工资",
                      text: _vm.basicInfo.baseSalary || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "绩效工资",
                      text: _vm.basicInfo.performanceSalary || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "社会保险",
                      text: _vm.basicInfo.socialInsurance || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "社险补贴",
                      text: _vm.basicInfo.socialInsuranceSubsidy || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "公积金",
                      text: _vm.basicInfo.providentFund || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "公积金补贴",
                      text: _vm.basicInfo.providentFundSubsidy || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "通讯补贴",
                      text: _vm.basicInfo.communicationSubsidy || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "交通补贴",
                      text: _vm.basicInfo.transportationSubsidy || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "餐贴",
                      text: _vm.basicInfo.mealSubsidy || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "汽油费补贴",
                      text: _vm.basicInfo.petrolSubsidy || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "岗位补贴",
                      text: _vm.basicInfo.jobRankSubsidy || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "其他补贴",
                      text: _vm.basicInfo.otherSubsidy || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "考核奖",
                      text: _vm.basicInfo.assessmentAward || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "高温费",
                      text: _vm.basicInfo.highTemperatureFee || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "社保基数",
                      text: _vm.basicInfo.socialInsuranceBase || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "公积金基数",
                      text: _vm.basicInfo.providentFundBase || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "养老基数",
                      text: _vm.basicInfo.pensionBase || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "生育基数",
                      text: _vm.basicInfo.fertilityBase || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "失业基数",
                      text: _vm.basicInfo.unemploymentBase || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "工伤基数",
                      text: _vm.basicInfo.workInjuryBase || "--",
                      span: 8,
                    },
                  }),
                  _c("col2-item", {
                    attrs: {
                      label: "医疗基数",
                      text: _vm.basicInfo.medicalBase || "--",
                      span: 8,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }